<template>
  <div class="tayo-header">
    <v-app-bar
      v-if="!headerReady"
      theme="dark"
      flat
      color="#000"
      style="width: 100vw;height:68px"
      height="68"
    />
    <v-app-bar
      v-if="headerReady"
      :clipped-left="clipped"
      theme="dark"
      flat
      color="#000"
      height="68"
    >
      <nuxt-link :to="'/' + $i18n.locale">
        <nuxt-img
          class="ml-3 mr-3 cursor-pointer"
          src="/img/logo/TAYO-white.svg"
          :alt="$t('alt.logo')"
          height="54"
          width="135"
          placeholder
        />
      </nuxt-link>
      <v-btn
        v-for="item in items"
        :key="item.to"
        class="hidden-sm-and-down text-capitalize"
        :style="pickColor(item)"
        :to="injectPath(item)"
      >
        {{ getTranslationFromLink(item.to) }}
      </v-btn>
      <v-spacer />
      <div class="hidden-sm-and-down">
        <div class=" d-flex child-flex mr-2">
          <v-btn
            v-for="(item) in locales"
            :key="item"
            :aria-label="item"
            :style="localSelected(item)"
            @click="changeLocale(item)"
          >
            {{ item.toUpperCase() }}
          </v-btn>
        </div>
      </div>

      <TayoButton class="hidden-sm-and-down mr-5" button-type="demo" />
      <v-app-bar-nav-icon
        aria-label="drawer"
        class="hidden-md-and-up"
        :icon="showDrawer ? mdiClose : undefined"
        @click.stop="drawer = !drawer"
      />
    </v-app-bar>
    <!-- Mobile menu -->
    <v-navigation-drawer
      v-if="headerReady"
      v-model="showDrawer"
      class="hidden-md-and-up mobileDrawer"
      persistent
      :clipped-right="clipped"
      disable-resize-watcher
      fixed
      app
      location="right"
      width="75%"
    >
      <v-list
        class="
          d-flex
          child-flex
          flex-column
          justify-end
          align-end"
      >
        <div v-for="(item) in items" :key="item.to">
          <v-list-item value="true" :to="injectPath(item)">
            <v-list-item-title
              :style="pickColor(item)"
              :ripple="false"
              v-text="getTranslationFromLink(item.to)"
            />
          </v-list-item>
        </div>
        <v-list-item>
          <TayoButton button-type="demo" />
        </v-list-item>
      </v-list>
      <div class="mobileLocalesWrapper d-flex child-flex mr-2">
        <v-btn
          v-for="(item) in locales"
          :key="item"
          :style="localSelected(item)"
          flat
          :aria-label="item"
          @click="changeLocale(item)"
        >
          {{ item.toUpperCase() }}
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mdiClose } from '@mdi/js'

export default {
  data () {
    return {
      mdiClose,
      clipped: true,
      drawer: false,
      headerReady: false,
      locales: ['en', 'fr', 'de'],
      items: [
        {
          to: '/solutions',
          fr: '/solution',
          de: '/losung'
        },
        {
          to: '/pricing',
          fr: '/prix',
          de: '/preis'
        },
        {
          to: '/team',
          fr: '/equipe',
          de: '/team'
        },
        {
          to: '/tayo-map',
          fr: '/tayo-map',
          de: '/tayo-map'
        },
        {
          to: '/blog',
          fr: '/blog',
          de: '/blog'
        }
      ],
      navigation: [
        {
          to: '/how-it-works',
          fr: '/comment-cela-fonctionne',
          de: '/wie-es-funktioniert'
        },
        {
          to: '/request-demo',
          fr: '/request-demo',
          de: '/request-demo'
        }
      ]
    }
  },
  computed: {
    showDrawer: {
      get: function () {
        if (!this.$vuetify.display.mobile) {
          return false
        } else {
          return this.drawer
        }
      },
      set: function (newValue) {
        this.drawer = newValue
      }
    }
  },
  mounted () {
    const locale = this.getLocale()
    if (window) {
      if (locale && ['fr', 'en', 'de'].includes(locale)) {
        this.$i18n.locale = locale
        localStorage.setItem('lang', this.$i18n.locale)
      } else if (localStorage.getItem('lang')) {
        this.$i18n.locale = localStorage.getItem('lang')
      }

      if (!localStorage.getItem('lang')) {
        const userLang = navigator.language || navigator.userLanguage
        this.$i18n.locale = userLang.split('-')[0]
        localStorage.setItem('lang', this.$i18n.locale)
      }

      if (!locale || !['fr', 'en', 'de'].includes(locale)) {
        this.$router.push('/' + this.$i18n.locale)
      }
      if (window.location.pathname === '/') {
        this.$router.push(this.$i18n.locale)
      }
    }

    this.headerReady = true
  },
  activated () {
    this.headerReady = true
  },
  methods: {
    getTranslationFromLink (link) {
      return this.$t('menu.' + link.replace('/', ''))
    },
    getNavbarItems () {
      let navbarItems = [] // this.mobileItems
      navbarItems = navbarItems.concat(this.items)
      return navbarItems
    },
    getLocaleFromPath () {
      const path = this.$route.fullPath.split('/')
      if (path.length > 0 && path[1].length === 2) {
        return path[1]
      } else {
        return null
      }
    },
    getLocale () {
      return this.$route.params.locale ? this.$route.params.locale : this.getLocaleFromPath()
    },
    changeLocale (locale) {
      const oldLocale = this.getLocale()
      this.$i18n.locale = locale
      localStorage.setItem('lang', this.$i18n.locale)
      // TODO: this.$cookie.set('locale', locale)

      const fullPath = this.$route.fullPath.split('/')
      if (fullPath.length <= 2) {
        return this.$router.push('/' + locale)
      } else {
        const path = fullPath[fullPath.length - 1]
        const navItems = this.items.concat(this.navigation)
        const route = navItems.find((x) => {
          if (!x) { return undefined }
          if (oldLocale === 'en' && x.to === '/' + path) {
            return x
          } else if (x[oldLocale] === '/' + path || (!x[oldLocale] && x.to === '/' + path)) {
            return x
          }
          return undefined
        })
        if (!route) {
          this.$router.push('/' + this.$i18n.locale)
          return
        }

        const newPath = route && route[locale] ? route[locale] : route.to
        this.$router.push('/' + this.$i18n.locale + newPath)
      }
    },
    localSelected (locale) {
      return this.$i18n.locale === locale ? 'color:#FFCC00' : 'color:white'
    },
    pickColor (item) {
      let path
      const locale = this.getLocale()
      if (locale === 'en') {
        path = '/' + locale + item.to
      } else {
        path = '/' + locale + (item[locale] ? item[locale] : item.to)
      }

      if (path === this.$route.path) {
        return 'color:#FFCC00'
      } else {
        return 'color:white;'
      }
    },
    injectPath (item) {
      if (this.getLocale() === 'en') {
        return '/' + this.getLocale() + item.to
      }
      const path = item[this.getLocale()] ? item[this.getLocale()] : item.to
      return '/' + this.getLocale() + path
    },
    injectLocale (path) {
      return '/' + this.getLocale() + path
    }
  }
}
</script>
<style lang="scss">
    .v-list-group--no-action > .v-list-group__items > div > .v-list-item {
        padding-left: 40px;
    }
  .mobileDrawer{
    background: rgba(0, 0, 0, 0.9);
    color: white;
    text-align: right;
    width:75%;
    .v-ripple__container {
      opacity: 0 !important;
    }
    button, .v-list{
      background: transparent;
      a.v-list-item{
        font-family: 'MaisonNeue', sans-serif;
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
      }
      .v-list-item--active{
        background-color: transparent;
        .v-list-item__overlay{
          opacity: 0;
        }
      }
    }
  }
  .mobileLocalesWrapper{
    position: absolute;
    right: 0;
    bottom: 10px;
    @media (orientation: landscape){
      left: 30px;
    }
    > .v-btn{
      min-width: 0;
      width: 30px;
    }
  }
  .tayo-header{
    .v-btn--active{
      background-color: transparent;
      .v-btn__overlay, .v-btn__underlay{
        opacity: 0;
      }
    }
  }
</style>
